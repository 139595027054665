


import Footer from './component/layout/footer';
import Header from './component/layout/header';
import Banner from './component/section/banner';
import Feature from './component/section/feature';
import HomeLayout from './component/section/homelayout';
import LayOut from './component/section/layout';
import LayOutTwo from './component/section/layout-2';
import LayOutThree from './component/section/layout-3';

function App() {
	return (
		<div className="App">
			<Header />
			<Banner />
			<HomeLayout />
			<LayOut />
			<LayOutTwo />
			<LayOutThree />
			<Feature />
			<Footer />
		</div>
	);
}

export default App;
