import { Component } from "react";

const title = "Start Your Website Today";
const desc = "Free Updates - Free Supports";
const btnText = "PURCHASE NOW";


class Footer extends Component {
    render() { 
        return (
            <footer className="footer text-center">
                <div className="footer-top">
                    <h2>{title}</h2>
                    <p>{desc}</p>
                    <a className="purchase-now" href="https://www.templatemonster.com/landing-page-template/niftyland-nft-landing-page-and-portfolio-react-js-template-251078.html" target="_blank">{btnText}</a>
                </div>
                <div className="footer-bottom">
                    <p>Copyright &copy; 2022. Design and Developed by <a href="https://www.templatemonster.com/authors/labartisan">LabArtisan</a></p>
                </div>
            </footer>
        );
    }
}
 
export default Footer;