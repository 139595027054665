import { Component } from "react";


const title = "NFT Landing Page React Template";
const desc = "Check Niftyland demos and create your websites that you only ever dreamed of. It's Really Easy to Edit higly customizable.";


class Banner extends Component {
    render() { 
        return (
            <section className="video-banner" style={{backgroundImage: "url(/assets/images/banner-bg.jpg)"}}>
                <div className="overlay"></div>
                <div className="bannar-conent">
                    <div className="bannar-conent-inner">
                        <h1>{title}</h1>
                        <p>{desc}</p>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Banner;