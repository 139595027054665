import { Component } from "react";

const title = "3 Different Landing page";
const desc = "Niftyland is designed with 3 different beautiful Landing pages layout . You can choose anyone for your website.";
const btnText = "PURCHASE NOW";


class HomeLayout extends Component {
    render() { 
        return (
            <section className="homepage-layout">
                <div className="overlay">
                    <div className="homepage-layout-content">
                        <h2><span>{title}</span></h2>
                        <p>{desc}</p>
                        <div className="purchase"><a href="https://www.templatemonster.com/landing-page-template/niftyland-nft-landing-page-and-portfolio-react-js-template-251078.html" target="_blank" className="default-btn">{btnText}</a></div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default HomeLayout;