import { Component } from "react";


const title = <h2><span className="boldest Meww-color">Meww NFT</span> Landing Page</h2>;
const desc = "Meww is superhero NFT landing page React template With all features section including About, Roadmap, FAQ, Team , Artist Details ,gallery & Community jonning section .You can make your NFT landing page website with this beautiful template";


class LayOutThree extends Component {
    render() { 
        return (
            <section className="landing-demo herox">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="landing-demo-content text-start mb-5 mb-lg-0">
                                {title}
                                <p className="mb-5">{desc}</p>
                                <a href="http://meww.labartisan.net" target="_blank" className="default-btn"> Live Preview</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="landingpage-image">
                                <a href="http://meww.labartisan.net" target="_blank">
                                    <img alt="search" src="assets/images/demo-img/home-2.jpg" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default LayOutThree;