import { Component } from "react";


const title = <h1>Niftyland - <span>Main Features</span></h1>;
const desc = "You deserve real flexibility while building your website and we are giving it!";


let featureList = [
    {
        imgUrl: 'assets/images/icons/icon-1.png',
        imgAlt: 'feature thumb',
        title: 'Unlimited Color',
    },
    {
        imgUrl: 'assets/images/icons/icon-2.png',
        imgAlt: 'feature thumb',
        title: 'Fully Responsive',
    },
    {
        imgUrl: 'assets/images/icons/icon-3.png',
        imgAlt: 'feature thumb',
        title: 'Google Font',
    },
    {
        imgUrl: 'assets/images/icons/icon-4.png',
        imgAlt: 'feature thumb',
        title: 'React js',
    },
    {
        imgUrl: 'assets/images/icons/icon-5.png',
        imgAlt: 'feature thumb',
        title: 'Easily Customizable',
    },
    {
        imgUrl: 'assets/images/icons/icon-6.png',
        imgAlt: 'feature thumb',
        title: 'Modern Design',
    },
    {
        imgUrl: 'assets/images/icons/icon-7.png',
        imgAlt: 'feature thumb',
        title: 'CSS 3',
    },
    {
        imgUrl: 'assets/images/icons/icon-8.png',
        imgAlt: 'feature thumb',
        title: 'JS Animations',
    },
    {
        imgUrl: 'assets/images/icons/icon-9.png',
        imgAlt: 'feature thumb',
        title: 'Clean Coded',
    },
    {
        imgUrl: 'assets/images/icons/icon-10.png',
        imgAlt: 'feature thumb',
        title: 'Cross Browser',
    },
    {
        imgUrl: 'assets/images/icons/icon-11.png',
        imgAlt: 'feature thumb',
        title: 'Well Documented',
    },
    {
        imgUrl: 'assets/images/icons/icon-12.png',
        imgAlt: 'feature thumb',
        title: '24/ 7 Support',
    },
]


class Feature extends Component {
    render() { 
        return (
            <section className="features-section" id="feature-section">
                <div className="container">
                    <div className="section-header text-center mb-5">
                        {title}
                        <p>{desc}</p>
                    </div>
                    <div className="row clearfix justify-content-center">
                        {featureList.map((val, i) => (
                            <div className="featured-block col-lg-3 col-md-4 col-sm-12" key={i}>
                                <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <span className="border-one"></span>
                                    <span className="border-two"></span>
                                    <div className="icon-box">
                                        <span className="icon"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></span>
                                    </div>
                                    <h5>{val.title}</h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Feature;