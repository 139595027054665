import { Component } from "react";


const title = <h2><span className="boldest">Bored Apes</span> (Singlepage Version)</h2>;
const desc = "Bored apes is apes NFT landing page React template With all features section including About, Roadmap, FAQ, Team , Artist Details & Community jonning section .You can make your NFT landing page website with this beautiful template";


class LayOutTwo extends Component {
    render() { 
        return (
            <section className="landing-demo cutemonster" id="demo-section">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-md-6">
                            <div className="landingpage-image">
                                <a href="http://bored.labartisan.net/index-single" target="_blank"><img alt="search" src="assets/images/demo-img/home-1.jpg" /></a>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="landing-demo-content mb-5 mb-lg-0 text-start">
                                {title}
                                <p className="mb-5">{desc}</p>
                                <a href="http://bored.labartisan.net/index-single" target="_blank" className="default-btn"> live Preview </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default LayOutTwo;